import React from 'react';

import Box from '@mui/material/Box';

const FormBase = React.forwardRef((props, ref) => {
  return <Box noValidate ref={ref} component="form" {...props} />;
});

FormBase.displayName = 'FormBase';
export default FormBase;
