import '@src/styles/rdr/styles.css';
import '@src/styles/rdr/default.css';

import React from 'react';
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import {DateRangePicker as BaseDateRangePicker} from "react-date-range";

function DateRangePicker({ startDate, endDate, onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const selectionRange = {
    startDate,
    endDate,
    key: 'selection',
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  const handleChange = (e) => {
    onChange(e);
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        Date: {formatDate(startDate)} - {formatDate(endDate)}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <BaseDateRangePicker
          ranges={[selectionRange]}
          onChange={handleChange}
        />
      </Popover>
    </React.Fragment>
  );
}

export default DateRangePicker;
