import packageJson from '../../package.json';

const _VERSION_FIELD_NAME = '__version__';
const _VERSION_FIELD_DEFAULT_VALUE = '';

function getAppVersion() {
  return window.localStorage.getItem(_VERSION_FIELD_NAME) || _VERSION_FIELD_DEFAULT_VALUE;
}

function setAppVersion(version) {
  window.localStorage.setItem(_VERSION_FIELD_NAME, version);
}

function getPackageVersion() {
  return packageJson.version;
}

export {
  getAppVersion,
  setAppVersion,
  getPackageVersion,
  _VERSION_FIELD_NAME,
  _VERSION_FIELD_DEFAULT_VALUE,
};
