import VerticalLayout from './Vertical';
import HorizontalLayout from './Horizontal';
import { useSettings } from "@src/hooks";

export default function AppLayout({ children }) {
  const { settings } = useSettings();

  return settings.layout === 'horizontal' ? (
    <HorizontalLayout>{children}</HorizontalLayout>
  ) : (
    <VerticalLayout>{children}</VerticalLayout>
  );
}
