import { useCallback, useState } from 'react';

import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';

import { logger } from '@src/plugins';

import useMountEffect from './useMountEffect';

// We can use useLocalStorage hook from @uidotdev/usehooks because it depends on react@experimental release
function getLocalStorageValue(key, initialValue) {
  if (isUndefined(window)) {
    return initialValue;
  }
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    // If error also return initialValue
    // eslint-disable-next-line no-console
    logger.error(error);
    return initialValue;
  }
}

function useLocalStorage(key, initialValue, config = {}) {
  const [storedValue, setStoredValue] = useState(() => getLocalStorageValue(key, initialValue));

  useMountEffect(() => {
    // Do not use crossTabSharing for big states sync. It may cause UI blocks.
    const { crossTabSharing = false } = config;
    const handleOnStorage = () => {
      setStoredValue(getLocalStorageValue(key, initialValue));
    };

    if (crossTabSharing && !isUndefined(window)) {
      window.addEventListener('storage', handleOnStorage);
    }

    return () => {
      if (crossTabSharing && !isUndefined(window)) {
        window.removeEventListener('storage', handleOnStorage);
      }
    };
  });

  const setValue = useCallback(
    (value) => {
      try {
        const valueToStore = isFunction(value) ? value(storedValue) : value;
        setStoredValue(valueToStore);
        if (!isUndefined(window)) {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        logger.error(error);
      }
    },
    [key, storedValue],
  );
  return [storedValue, setValue];
}

export default useLocalStorage;
