import React, { useContext, useCallback, useMemo, useReducer } from 'react';

import { nanoid } from 'nanoid';

const SidebarContext = React.createContext(null);

const useSidebar = () => useContext(SidebarContext);

const initialState = {
  isOpen: true,
  elementId: nanoid(),
  selectedItem: null,
};

const MUTATION_TYPES = {
  OPEN: 'sidebar__open',
  CLOSE: 'sidebar__close',
  TOGGLE: 'sidebar__toggle',
  SELECT: 'sidebar__select',
};

function reducer(state, action) {
  switch (action.type) {
  case MUTATION_TYPES.OPEN:
    return {
      ...state,
      isOpen: true,
    };
  case MUTATION_TYPES.CLOSE:
    return {
      ...state,
      isOpen: false,
    };
  case MUTATION_TYPES.TOGGLE:
    return {
      ...state,
      isOpen: !state.isOpen,
    };
  case MUTATION_TYPES.SELECT:
    return {
      ...state,
      selectedItem: action.payload.item,
    };
  default:
    throw new Error('Unexpected action type.');
  }
}

function SidebarProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const open = useCallback(() => {
    dispatch({ type: MUTATION_TYPES.OPEN });
  }, []);

  const close = useCallback(() => {
    dispatch({ type: MUTATION_TYPES.CLOSE });
  }, []);

  const toggle = useCallback(() => {
    dispatch({ type: MUTATION_TYPES.TOGGLE });
  }, []);

  const select = useCallback((item) => {
    dispatch({ type: MUTATION_TYPES.SELECT, payload: { item } });
  }, []);

  const contextValue = useMemo(
    () => ({
      state,
      open,
      close,
      toggle,
      select,
    }),
    [state, open, close, toggle, select],
  );

  return <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>;
}

SidebarProvider.displayName = 'SidebarProvider';
export { SidebarProvider, useSidebar };
