import React from 'react';

import PropTypes from 'prop-types';

const parse = (p) => (Array.isArray(p) ? [p[0], p[1]] : [p, {}]);

function ProviderComposer({ providers, children }) {
  return (
    <>
      {providers.reduceRight((hierarchy, provider) => {
        const [Provider, props] = parse(provider);
        return <Provider {...props}>{hierarchy}</Provider>;
      }, children)}
    </>
  );
}

ProviderComposer.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.elementType])),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
ProviderComposer.displayName = 'ProviderComposer';
export default ProviderComposer;
