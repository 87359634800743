import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';

function Submit(props) {
  return <LoadingButton {...props} type="submit" />;
}

Submit.dipplayName = 'Submit';
export default Submit;
