import React, { useContext, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import isNull from 'lodash/isNull';

import defaultClient from '@src/api/clients/default';
import config from '@src/config';
import { useLocalStorage } from '@src/hooks';
import { httpUtils } from '@src/utils';

const AuthContext = React.createContext(null);

const useAuth = () => useContext(AuthContext);

const LOGIN_URL = 'accounts/auth/login/';
const LOGOUT_URL = 'accounts/auth/logout/';
const TOKEN_VERIFY_URL = 'accounts/auth/token/verify/';
const TOKEN_REFRESH_URL = 'accounts/auth/token/refresh/';

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrl = useMemo(() => searchParams.get('next'), [searchParams]);
  const [state, setState] = useLocalStorage('auth', null, { crossTabSharing: true });

  const isAuthenticated = useMemo(() => {
    return !isNull(state);
  }, [state]);

  const authenticate = useCallback((data) => {
    setState(data);
  }, [setState]);

  const unauthenticate = useCallback(() => {
    setState(null);
  }, [setState]);

  const login = useCallback(
    (payload) => {
      return new Promise((resolve, reject) => {
        defaultClient
          .request({ url: LOGIN_URL, method: 'POST', auth: false, payload })
          .then((response) => {
            if (httpUtils.isOk(response)) {
              authenticate(response.data);
              navigate(redirectUrl || config.INDEX_PAGE);
            }
            resolve(response);
          })
          .catch((error) => {
            unauthenticate();
            reject(error);
          });
      });
    },
    [authenticate, navigate, redirectUrl, unauthenticate],
  );

  const logout = useCallback(() => {
    return new Promise((resolve, reject) => {
      defaultClient
        .request({ url: LOGOUT_URL, method: 'POST', auth: false })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          unauthenticate();
          navigate(config.LOGIN_URL);
        });
    });
  }, [navigate, unauthenticate]);

  const refresh = useCallback(() => {
    return new Promise((resolve, reject) => {
      const { refresh_token = null } = state || '';
      if (!refresh_token) {
        reject(new Error('RefreshToken not found.'));
      } else {
        defaultClient
          .request({ url: TOKEN_REFRESH_URL, method: 'POST', auth: false, payload: { refresh_token } })
          .then((response) => {
            authenticate(response.data);
            resolve();
          })
          .catch((error) => {
            unauthenticate();
            reject(error);
          });
      }
    });
  }, [authenticate, state, unauthenticate]);

  const verify = useCallback(() => {
    return new Promise((resolve, reject) => {
      const { access_token = null } = state || '';

      if (!access_token) {
        reject(new Error('AccessToken not found.'));
      } else {
        defaultClient
          .request({ url: TOKEN_VERIFY_URL, method: 'POST', auth: false, payload: { token: access_token } })
          .then(resolve)
          .catch((error) => {
            if (httpUtils.isUnauthorizedStatus(error.response)) {
              refresh().then(resolve).catch(reject);
            } else {
              unauthenticate();
              reject(error);
            }
          });
      }
    });
  }, [refresh, state, unauthenticate]);

  const contextValue = useMemo(
    () => ({
      state,
      login,
      logout,
      verify,
      authenticate,
      unauthenticate,
      isAuthenticated,
    }),
    [
      state,
      login,
      logout,
      verify,
      authenticate,
      unauthenticate,
      isAuthenticated,
    ],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

AuthProvider.displayName = 'AuthProvider';
export { AuthProvider, useAuth };
