import '@src/styles/rdr/styles.css';
import '@src/styles/rdr/default.css';

import React from 'react';

import { createRoot } from 'react-dom/client';

import config from '@src/config';
import { sentryPlugin } from '@src/plugins';
import { localStorageUtils, versionUtils } from '@src/utils';

import App from './App';

function init() {
  return new Promise((resolve, reject) => {
    const currentVersion = versionUtils.getPackageVersion();
    const previousVersion = versionUtils.getAppVersion();

    if (previousVersion !== currentVersion) {
      Promise.all([localStorageUtils.clearLocalStorage()])
        .then(() => {
          versionUtils.setAppVersion(currentVersion);
          resolve({ version: currentVersion });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve({ version: currentVersion });
    }
  });
}

init()
  .then(({ version }) => {
    // eslint-disable-next-line no-console
    console.debug(`Init | App - v${version}`);

    // Verify config
    config.init();

    // Init sentry config
    sentryPlugin.init();

    // Render Main App
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<App />);
  })
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.error('App Init Error: ', error);
  });
