export default function getSettings() {
  const settingsStr = window.localStorage.getItem('app.settings');
  let settings;

  if (settingsStr) {
    try {
      settings = JSON.parse(settingsStr);
    } catch {
      console.error('Unable to parse the settings');
    }
  }

  settings ||= {};

  return settings;
}
