import defaultClient from '@src/api/clients/default';

const URL = (path) => {
  return `dashboard/client-performance/${path}/`
}

export function fetchClientPerformanceByClient(payload = {}) {
  return defaultClient.request({
    url: URL('client'),
    payload
  })
}

export function fetchClientPerformanceByChannel(payload = {}) {
  return defaultClient.request({
    url: URL('channel'),
    payload
  })
}

export function fetchClientPerformanceByCampaign(payload = {}) {
  return defaultClient.request({
    url: URL('campaign'),
    payload
  })
}

export function fetchClientPerformanceByAdSet(payload = {}) {
  return defaultClient.request({
    url: URL('ad-set'),
    payload
  })
}

export function fetchClientPerformanceByAd(payload = {}) {
  return defaultClient.request({
    url: URL('ad'),
    payload
  })
}
