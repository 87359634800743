import React from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import { AuthLayout, AppLayout, ProviderComposer } from '@src/components';
import { I18nProvider } from '@src/components/core/i18n-provider';
import { LocalizationProvider } from '@src/components/core/localization-provider';
import { ScrollRestoration } from '@src/components/core/scroll-restoration';
import { Toaster } from '@src/components/core/toaster';
import config from '@src/config';
import { VerifyContainer } from '@src/containers';
import { AuthProvider, MessageProvider, SidebarProvider } from '@src/context/common';
import {
  Login,
  Page404,
  Page500,
  ErrorBoundary,
  DailyPulse,
  ClientPerformance
} from '@src/pages';
import { DailyPulseProvider, ClientPerformanceProvider } from '@src/context';

import Protect from './Protect';

const PROVIDERS = [
  LocalizationProvider,
  MessageProvider,
  AuthProvider,
  [I18nProvider, { language: 'en' }],
  SidebarProvider,
  DailyPulseProvider,
  ClientPerformanceProvider,
];

function RootContainer() {
  return (
    <ErrorBoundary>
      <Helmet>
        <title>{config.SITE.title}</title>
      </Helmet>
      <ProviderComposer providers={PROVIDERS}>
        <ScrollRestoration/>
        <VerifyContainer>
          <Outlet/>
          <Toaster position="bottom-right"/>
        </VerifyContainer>
      </ProviderComposer>
    </ErrorBoundary>
  );
}

function AppContainer() {
  return (
    <Protect.Authenticated>
      <AppLayout>
        <Outlet/>
      </AppLayout>
    </Protect.Authenticated>
  );
}

function AuthContainer() {
  return (
    <Protect.Any>
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    </Protect.Any>
  );
}


// https://reactrouter.com/en/main/start/overview
const router = createBrowserRouter([
  {
    element: <RootContainer />,
    children: [
      {
        path: '/',
        element: <AuthContainer />,
        children: [
          {
            path: '',
            element: <Login />,
          },
          {
            path: 'auth/login',
            element: <Login />,
          },
        ],
      },
      {
        path: '/',
        element: <AppContainer />,
        children: [
          {
            path: 'daily-pulse',
            element: <DailyPulse />,
          },
          {
            path: 'client-performance',
            element: <ClientPerformance />,
          },
        ],
      },
      {
        path: '404',
        element: <Protect.Any><Page404 /></Protect.Any>,
      },
      {
        path: '500',
        element: <Protect.Any><Page500 /></Protect.Any>,
      },
      {
        path: '*',
        element: <Protect.Any><Page404 /></Protect.Any>,
      },
    ],
  },

]);

export default router;
