import * as React from 'react';

import {DateRangePicker, DollarFormatter, Page, PercentageFormatter} from "@src/components";
import { DataTable } from "@src/components/core/data-table";
import {useDailyPulse} from "@src/context";
import {TableContainer} from "@mui/material";

export function DailyPulse() {
  const {
    data,
    isLoading,
    isError,
    loadData,
    startDate,
    endDate,
    setDateRange
  } = useDailyPulse();

  const columns = [
    {
      field: 'client__name',
      name: 'Client',
      width: '150px'
    },
    {
      field: 'channel__name',
      name: 'Channel',
      width: '200px'
    },
    {
      field: 'campaign',
      name: 'Campaign',
      width: '250px'
    },
    {
      name: 'Spend',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.total_spend_sum} />
      )
    },
    {
      field: 'total_leads_sum',
      name: 'Leads',
      width: '200px'
    },
    {
      field: 'out_of_areas_count_sum',
      name: 'Out Of Areas',
      width: '200px'
    },
    {
      field: 'success_sum',
      name: 'Success',
      width: '200px'
    },
    {
      field: 'accepted_sum',
      name: 'Accepted',
      width: '200px'
    },
    {
      name: 'CP Lead',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.cost_per_lead_avg} />
      )
    },
    {
      name: 'CP Accepted',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.cost_per_accepted_avg} />
      )
    },
    {
      name: 'Out Of Area Rate',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.out_of_area_avg} />
      )
    },
    {
      name: 'Accepted Rate',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.accepted_avg} />
      )
    },
    {
      field: 'self_books_sum',
      name: 'Self Books',
      width: '200px'
    },
    {
      name: 'CP Self Book',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.cost_per_self_book_avg} />
      )
    },
    {
      name: 'Self Book Rate',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.self_book_rate_avg} />
      )
    },
    {
      name: 'CTR',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.click_through_rate_avg} />
      )
    },
    {
      field: 'conversion_rate_avg',
      name: 'CVR',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.conversion_rate_avg} />
      )
    },
  ];

  const handleRowClick = (e, target) => {
    loadData(target);
  }

  const handleDateRangeChange = (e) => {
    const { startDate, endDate } = e.selection;
    setDateRange(startDate, endDate);
  }

  return (
    <Page
      title="Daily Pulse"
      errorCode={isError ? 500 : null}
      isLoading={isLoading}
    >
      <TableContainer>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={handleDateRangeChange}
        />
        <DataTable
          columns={columns}
          rows={data}
          onClick={handleRowClick}
          hover
        />
      </TableContainer>
    </Page>
  )
}

DailyPulse.displayName = 'DailyPulse';
export default DailyPulse;
