import { useCallback, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import noop from 'lodash/noop';
import { useForm as useFormBase } from 'react-hook-form';

import { logger } from '@src/plugins';
import { httpUtils, validatorUtils } from '@src/utils';

function getErrorResponse(error) {
  const responseData = error.response.data;
  return responseData;
}
function hasKey(key, schema) {
  if (schema.type === 'object') {
    return key in schema.fields;
  }
  if (schema.type === 'array') {
    if (schema.innerType.type === 'object') {
      return hasKey(key, schema.innerType);
    }
  }
  return false;
}

function useForm({ schema, defaultValues, resetAfterSuccessSubmit = false, ...rest }) {
  const counter = useRef(null);
  const { setError, handleSubmit, reset, ...form } = useFormBase({
    ...rest,
    defaultValues,
    resolver: yupResolver(schema, { form: { defaultValues } }),
  });

  const setErrors = useCallback(
    (data, nonFieldError = () => {}) => {
      counter.current = 0;
      const parseErrors = (s, d, p = '') => {
        if (counter.current >= 10000) {
          throw new Error('Unable to parse errors.');
        }
        counter.current += 1;
        // eslint-disable-next-line no-restricted-syntax
        for (const pair of Object.entries(d)) {
          const [key, value] = pair;
          const errorKey = p ? `${p}.${key}` : key;
          if (isString(value)) {
            if (hasKey(key, s)) {
              setError(errorKey, {
                type: 'invalid',
                message: value,
              });
            } else {
              nonFieldError({ key: errorKey, detail: value });
              break;
            }
          } else if (isArray(value)) {
            if (hasKey(key, s)) {
              value.forEach((item, index) => {
                if (isString(item)) {
                  setError(errorKey, {
                    type: 'invalid',
                    message: value,
                  });
                } else if (isObject(item)) {
                  const _errorKey = `${errorKey}[${index}]`;
                  parseErrors(s.fields[key], item, _errorKey);
                }
              });
            } else {
              nonFieldError({ key: errorKey, detail: value });
              break;
            }
          } else if (isObject(value)) {
            parseErrors(s.fields[key], value, errorKey);
          }
        }
      };

      try {
        parseErrors(schema, data);
      } catch (error) {
        // eslint-disable-next-line no-console
        logger.error('Unable to parse errors.');
      }
    },
    [schema, setError],
  );

  const handleSubmitExtended = useCallback(
    ({ onSubmit = noop, onSuccess = noop, onError = noop, onFinally = noop, onInvalid = noop }) => {
      return handleSubmit((values) => {
        const promise = onSubmit(values, { form, schema, defaultValues });
        if (validatorUtils.isPromise(promise)) {
          return new Promise((resolve) => {
            promise
              .then((response) => {
                if (resetAfterSuccessSubmit) {
                  reset();
                }
                onSuccess(response, { form, schema, defaultValues });
                resolve();
              })
              .catch((error) => {
                if (httpUtils.isBadRequest(error?.response)) {
                  setErrors(getErrorResponse(error));
                  onError(error, { form, schema, defaultValues, isFieldError: true });
                } else {
                  onError(error, { form, schema, defaultValues, isFieldError: false });
                }
                resolve();
              }).finally(() => {
                onFinally(values, { form, schema, defaultValues });
              });
          });
        }
        // eslint-disable-next-line no-console
        logger.debug('Non Promise result. Form submitting attributes may not working.');
        return Promise.resolve();
      }, onInvalid);
    },
    [handleSubmit, form, schema, defaultValues, resetAfterSuccessSubmit, reset, setErrors],
  );

  return {
    reset,
    setError,
    setErrors,
    handleSubmitBase: handleSubmit,
    handleSubmit: handleSubmitExtended,
    ...form,
  };
}

export default useForm;
