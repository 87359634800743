import * as Sentry from '@sentry/react';

// https://docs.sentry.io/platforms/javascript/guides/react/#configure
function getConfig() {
  return {
    dsn: import.meta.env.VITE_SENTRY_DSN_FRONTEND,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    // Or however deep you want your state context to be.
    normalizeDepth: 10,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0,
    integrations: [],
    ignoreErrors: [],
  };
}

function shouldInit() {
  return import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN_FRONTEND !== 'off';
}

function init() {
  // eslint-disable-next-line no-console
  console.debug('Init | Sentry.');
  if (shouldInit()) {
    const config = getConfig();
    Sentry.init(config);
  }
}

export { init, getConfig, shouldInit };
