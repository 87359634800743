import defaultClient from '@src/api/clients/default';

const URL = (path) => {
  return `dashboard/daily-pulse/${path}/`
}

export function fetchDailyPulseByClient(payload = {}) {
  return defaultClient.request({
    url: URL('client'),
    payload
  })
}

export function fetchDailyPulseByChannel(payload = {}) {
  return defaultClient.request({
    url: URL('channel'),
    payload
  })
}

export function fetchDailyPulseByCampaign(payload = {}) {
  return defaultClient.request({
    url: URL('campaign'),
    payload
  })
}
