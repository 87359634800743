import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';

import { FormBase, Submit, TextField } from '@src/components/form';
import { useForm } from '@src/hooks';


const schema = Yup.object().shape({
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  password1: Yup.string()
    .required('This field is required')
    .min(8, 'Too Short!'),
  password2: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('password1'), null], 'Passwords must match')
    .min(8, 'Too Short!'),
  email: Yup.string('Enter your Email')
    .email('Enter a valid email')
    .required('Email is required'),
});

const DEFAULT_VALUES = {
  emil: '',
  firstName: '',
  lastName: '',
  password1: '',
  password2: '',
};

function SignUp() {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ schema, defaultValues: DEFAULT_VALUES });

  const onSubmit = (data) => {
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h3">
          Sign Up
        </Typography>
        <FormBase onSubmit={handleSubmit({ onSubmit })} sx={{ mt: 6 }}>


          <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={6}>
              <TextField
                control={control}
                margin="none"
                required
                fullWidth
                id="firstName"
                name="firstName"
                placeholder="Enter first name"
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                control={control}
                margin="none"
                required
                fullWidth
                id="lastName"
                name="lastName"
                placeholder="Enter last name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                control={control}
                margin="none"
                required
                fullWidth
                id="email"
                name="email"
                placeholder="Enter email"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                control={control}
                margin="none"
                required
                fullWidth
                id="password1"
                name="password1"
                type='password'
                placeholder="Enter password"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                control={control}
                margin="none"
                type='password'
                required
                fullWidth
                id="password2"
                name="password2"
                placeholder="Enter confirm password"
                autoFocus
              />

            </Grid>
          </Grid>
          <Submit
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            fullWidth
            loading={isSubmitting}
          >
            Register
          </Submit>
        </FormBase>
      </Box>
    </Container>
  );
}


SignUp.displayName = 'SignUp';
export default SignUp;
