function clearLocalStorage() {
  return new Promise((resolve, reject) => {
    try {
      window.localStorage.clear();
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

export { clearLocalStorage };
