import '@src/styles/global.scss';

import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { ThemeProvider } from '@src/components/core/theme-provider/theme-provider';
import { SettingsProvider } from "@src/context";
import config from '@src/config';
import router from '@src/router';
import { applyDefaultSettings, getSettings } from "@src/corelib";
import { SettingsButton } from '@src/components/core/settings/settings-button';
import { Toaster } from '@src/components/core/toaster';

function AppBase() {
  const settings = React.useRef(applyDefaultSettings(getSettings()));
  return (
    <HelmetProvider>
      <Helmet>
        <title>{config.SITE.title}</title>
      </Helmet>
      <SettingsProvider settings={settings.current}>
        <ThemeProvider>
          <RouterProvider router={router}/>
          <SettingsButton />
          <Toaster position="bottom-right" />
        </ThemeProvider>
      </SettingsProvider>
    </HelmetProvider>
  );
}

export default function App() {
  if (config.STRICT) {
    return (
      <React.StrictMode>
        <AppBase />
      </React.StrictMode>
    );
  }
  return <AppBase />;
}
