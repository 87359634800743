import * as React from 'react';

import {DateRangePicker, DollarFormatter, Page, PercentageFormatter} from "@src/components";
import { DataTable } from "@src/components/core/data-table";
import {useClientPerformance} from "@src/context";
import {TableContainer} from "@mui/material";

export function ClientPerformance() {
  const {
    data,
    isLoading,
    isError,
    loadData,
    startDate,
    endDate,
    setDateRange
  } = useClientPerformance();

  const columns = [
    {
      field: 'client__name',
      name: 'Client',
      width: '150px'
    },
    {
      field: 'channel__name',
      name: 'Channel',
      width: '200px'
    },
    {
      field: 'campaign',
      name: 'Campaign',
      width: '250px'
    },
    {
      field: 'ad_set',
      name: 'Ad Set',
      width: '250px'
    },
    {
      field: 'ad',
      name: 'Ad',
      width: '250px'
    },
    {
      name: 'Spend',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.total_spend_sum} />
      )
    },
    {
      field: 'total_leads_sum',
      name: 'Leads',
      width: '200px'
    },
    {
      field: 'self_books_sum',
      name: 'Self Books',
      width: '200px'
    },
    {
      field: 'apps_booked_sum',
      name: 'Apps Booked',
      width: '200px'
    },
    {
      field: 'scheduled_sum',
      name: 'Scheduled',
      width: '200px'
    },
    {
      field: 'issue_sum',
      name: 'Issue',
      width: '200px'
    },
    {
      field: 'demo_sum',
      name: 'Demos',
      width: '200px'
    },
    {
      field: 'gross_sold_sum',
      name: 'Gross Sold',
      width: '200px'
    },
    {
      field: 'net_sold_sum',
      name: 'Net Sold',
      width: '200px'
    },
    {
      name: 'Gross $$$',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.gross_total_sum} />
      )
    },
    {
      name: 'Net $$$',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.net_total_sum} />
      )
    },
    {
      name: 'Gross COM',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.gross_com_avg} />
      )
    },
    {
      name: 'Net COM',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.net_com_avg} />
      )
    },
    {
      name: 'CP Lead',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.cost_per_lead_avg} />
      )
    },
    {
      name: 'CP Self Book',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.cost_per_self_book_avg} />
      )
    },
    {
      name: 'CP App',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.cost_per_app_avg} />
      )
    },
    {
      name: 'CP Schedule',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.cost_per_schedule_avg} />
      )
    },
    {
      name: 'CP Issue',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.cost_per_issue_avg} />
      )
    },
    {
      name: 'CP Demo',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.cost_per_demo_avg} />
      )
    },
    {
      name: 'CP Gross Sold',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.cost_gross_sold_avg} />
      )
    },
    {
      name: 'CP Net Sold',
      width: '200px',
      formatter: (row) => (
        <DollarFormatter value={row.cost_net_sold_avg} />
      )
    },
    {
      field: 'conversion_rate_avg',
      name: 'CVR',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.conversion_rate_avg} />
      )
    },
    {
      name: 'Self Book Rate',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.self_book_rate_avg} />
      )
    },
    {
      name: 'Booked Rate',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.booked_rate_avg} />
      )
    },
    {
      name: 'Issue Rate',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.issue_rate_avg} />
      )
    },
    {
      name: 'Demo Rate',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.demo_rate_avg} />
      )
    },
    {
      name: 'Gross Sold Rate',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.gross_sold_rate_avg} />
      )
    },
    {
      name: 'Net Sold Rate',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.net_sold_rate_avg} />
      )
    },
    {
      name: 'Lead To Net Sale',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.lead_to_net_sale_avg} />
      )
    },
    {
      name: 'Lead To Issue',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.lead_to_issue_avg} />
      )
    },
    {
      name: 'Issue To  Net Sale',
      width: '200px',
      formatter: (row) => (
        <PercentageFormatter value={row.issue_to_net_sale_avg} />
      )
    },
  ];

  const handleRowClick = (e, target) => {
    loadData(target);
  }

  const handleDateRangeChange = (e) => {
    const { startDate, endDate } = e.selection;
    setDateRange(startDate, endDate);
  }

  return (
    <Page
      title="Client Performance"
      errorCode={isError ? 500 : null}
      isLoading={isLoading}
    >
      <TableContainer>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={handleDateRangeChange}
        />
        <DataTable
          columns={columns}
          rows={data}
          onClick={handleRowClick}
          hover
        />
      </TableContainer>
    </Page>
  )
}

ClientPerformance.displayName = 'ClientPerformance';
export default ClientPerformance;
