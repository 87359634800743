import config from '@src/config';

export default function applyDefaultSettings(settings) {
  return {
    colorScheme: config.SITE.colorScheme,
    primaryColor: config.SITE.primaryColor,
    direction: 'ltr',
    navColor: 'evident',
    layout: 'vertical',
    ...settings,
  };
}
