const HTTP_STATUS_CODES = {
  OK: { code: '200', reason: 'OK' },
  CREATED: { code: '201', reason: 'Created' },
  BAD_REQUEST: { code: '400', reason: 'Bad Request' },
  UNAUTHORIZED: { code: '401', reason: 'Unauthorized' },
  FORBIDDEN: { code: '403', reason: 'Forbidden' },
  NOT_FOUND: { code: '404', reason: 'Not Found' },
  INTERNAL_SERVER_ERROR: { code: '500', reason: 'Internal Server Error' },
};

function checkResponseStatusCode(response, status) {
  return response?.status?.toString() === status.code;
}

function isUnauthorizedStatus(response) {
  return checkResponseStatusCode(response, HTTP_STATUS_CODES.UNAUTHORIZED);
}

function isBadRequest(response) {
  return checkResponseStatusCode(response, HTTP_STATUS_CODES.BAD_REQUEST);
}

function isNotFound(response) {
  return checkResponseStatusCode(response, HTTP_STATUS_CODES.NOT_FOUND);
}

function isInternalServerError(response) {
  return checkResponseStatusCode(response, HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR);
}

function isForbidden(response) {
  return checkResponseStatusCode(response, HTTP_STATUS_CODES.FORBIDDEN);
}

function isOk(response) {
  return checkResponseStatusCode(response, HTTP_STATUS_CODES.OK);
}

export {
  HTTP_STATUS_CODES,
  checkResponseStatusCode,
  isUnauthorizedStatus,
  isBadRequest,
  isNotFound,
  isInternalServerError,
  isForbidden,
  isOk,
};
