import { logger } from '@src/plugins';
import { LogLevel } from '@src/plugins/logger';

function Config({ DEBUG }) {
  // Main
  this.DEBUG = DEBUG;
  this.STRICT = true;
  this.LOG_LEVEL = import.meta.env.VITE_LOG_LEVEL || LogLevel.ALL;

  this.SITE = {
    title: 'LeadGurus Pro',
    description: '',
    colorScheme: 'light',
    primaryColor: 'chateauGreen',
    navColor: 'evident',
  };

  // Authorization and Authentication
  this.INDEX_PAGE = '/';
  this.LOGIN_URL = '/auth/login';
  this.LOGOUT_URL = '/auth/logout';

  this.init = () => {
    if (this.DEBUG) {
      logger.debug('Load Config: Debug Mode.');
    } else {
      logger.debug('Load Config: Production Mode.');
    }
  };
}

function getConfig() {
  if (import.meta.env.DEV) {
    return new Config({ DEBUG: true });
  }
  return new Config({ DEBUG: false });
}

const config = getConfig();
export default config;
