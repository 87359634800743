import React, { useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useAuth } from '@src/context/common';
import { useMountEffect } from '@src/hooks';

function VerifyContainer({ children }) {
  const { verify } = useAuth();
  const [isVerified, setIsVerified] = useState(false);

  useMountEffect(() => {
    const handleOnVerify = () => {
      setIsVerified(false);
      verify()
        .catch(() => {
        // eslint-disable-next-line no-console
          console.info('Verification failed.');
        })
        .finally(() => {
          setIsVerified(true);
        });
    };

    handleOnVerify();
  });

  if (!isVerified) {
    return (
      <Box
        width="100%"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress />
      </Box>
    );
  }
  return children;
}

VerifyContainer.displayName = 'VerifyContainer';
export default VerifyContainer;
