import axios from 'axios';

import config from '@src/config';
import { formUtils, httpUtils } from '@src/utils';

function DefaultClientConfig() {
  this.API_VERSION = 'api';
  this.PROTO = import.meta.env.SSL === 'on' ? 'https' : 'http';
  this.DOMAIN = import.meta.env.DOMAIN;
  this.BASE_URL = `${this.PROTO}://${this.DOMAIN}`;
  this.API_URL = `${this.BASE_URL}/${this.API_VERSION}`;
  console.log(this.API_URL)
}

const defaultClientConfig = new DefaultClientConfig();

function getCredentials() {
  return localStorage.getItem('auth')
    ? JSON.parse(localStorage.getItem('auth'))
    : {} || {};
}

function setCredentials(credentials) {
  return localStorage.setItem('auth', JSON.stringify(credentials));
}

function removeCredentials() {
  return localStorage.removeItem('auth');
}

function redirectToLoginPage() {
  window.location.replace(config.LOGIN_URL);
}

function createDefaultAxiosClient() {
  const instance = axios.create({ baseURL: defaultClientConfig.API_URL });

  // Config axios options here
  instance.defaults.withCredentials = true;
  instance.defaults.headers.common['Content-Type'] = formUtils.CONTENT_TYPES.JSON;
  return instance;
}

const defaultAxiosClient = createDefaultAxiosClient();

function createAuthAxiosClient() {
  const instance = axios.create({ baseURL: defaultClientConfig.API_URL });

  // Config axios options here
  instance.defaults.withCredentials = true;
  instance.defaults.headers.common['Content-Type'] = formUtils.CONTENT_TYPES.JSON;
  instance.interceptors.response.use(undefined, (error) => {
    if (httpUtils.isUnauthorizedStatus(error.response)) {
      const { refresh_token = null } = getCredentials();

      if (!refresh_token) {
        removeCredentials();
        redirectToLoginPage();
        return Promise.reject(new Error('RefeshToken parse error.'));
      }

      return defaultAxiosClient
        .post('accounts/auth/token/refresh/', { refresh_token })
        .then((response) => {
          setCredentials(response.data);
          // eslint-disable-next-line no-param-reassign
          error.config.headers.Authorization = `Bearer ${response.data.access_token}`;
          return instance.request(error.config);
        })
        .catch(() => {
          removeCredentials();
          redirectToLoginPage();
          return Promise.reject(new Error('RefeshToken ontain error.'));
        });
    }
    return Promise.reject(error);
  });
  return instance;
}

const authAxiosClient = createAuthAxiosClient();

function request({
  auth = true,
  url = '',
  method = 'GET',
  payload = null,
  multipart = false,
  headers = {},
  options = {},
  serializer = formUtils.serialize,
}) {
  let jsonOrFormData = payload;
  let instance = null;
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

  if (auth) {
    // Set axiosInstanceWithAuth as base
    instance = authAxiosClient;
    // Get access token from auth state
    const { access_token = null } = getCredentials();
    // Access token not founded
    if (!access_token) {
      removeCredentials();
      redirectToLoginPage();
      return Promise.reject(new Error('AccessToken parse error.'));
    }

    // Access token founded
    instance.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  } else {
    // Set axiosInstanceWithoutAuth as base
    instance = defaultAxiosClient;
  }

  // Multipart
  if (multipart) {
    if (!(payload instanceof FormData)) {
      jsonOrFormData = serializer(payload);
    }
    instance.defaults.headers.common['Content-Type'] = formUtils.CONTENT_TYPES.MULTIPART;
  }

  return new Promise((resolve, reject) => {
    instance
      .request({
        url,
        method,
        headers,
        [dataOrParams]: jsonOrFormData,
        ...options,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const defaultClient = { request };

export default defaultClient;
export { authAxiosClient, defaultAxiosClient };
