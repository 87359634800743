import * as React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import config from '@src/config';
import {Page404, Page500} from "@src/pages";
import CircularProgress from "@mui/material/CircularProgress";

export function Page({ title, children, errorCode = null, isLoading = false }) {
  const metadata = { title: `${title} | ${config.SITE.title}` };
  const { t } = useTranslation();

  switch (errorCode) {
    case 500:
      return <Page500 />;
    case 404:
      return <Page404 />;
  }

  if (isLoading) {
    return (
      <Box
        width="100%"
        height="calc(100vh - 57px)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{metadata.title}</title>
      </Helmet>
      <Box
        sx={{
          maxWidth: 'var(--Content-maxWidth)',
          m: 'var(--Content-margin)',
          p: 'var(--Content-padding)',
          width: 'var(--Content-width)',
        }}
      >
        <Stack spacing={4}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ alignItems: 'flex-start' }}>
            <Box sx={{ flex: '1 1 auto' }}>
              <Typography variant="h4">{title}</Typography>
            </Box>
          </Stack>
          <Stack>
            {children}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

Page.displayName = 'Page';
export default Page;
