
function getCurrentYear() {
  return new Date().getFullYear();
}

function getCurrentWeekDates() {
    const today = new Date();

    const monday = new Date(today);
    const dayOfWeek = monday.getDay(); // Sunday is 0, Saturday is 6
    const daysToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
    monday.setDate(monday.getDate() + daysToMonday);

    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);

    return [
      monday,
      sunday
    ];
}

function formatDateToYMD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export { getCurrentYear, getCurrentWeekDates, formatDateToYMD };
