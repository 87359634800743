import React from 'react';

import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

function makeController(Component) {
  function Wrapper({ name, control, error, helperText, ...restProps }) {
    if (control) {
      const renderField = ({ field, fieldState }) => {
        const _error = Boolean(fieldState.error) || error;
        const _helperText = fieldState.error?.message || helperText || ' ';
        return <Component error={_error} helperText={_helperText} {...restProps} {...field} />;
      };
      return <Controller name={name} control={control} render={renderField} />;
    }
    return <Component name={name} error={error} helperText={helperText} {...restProps} />;
  }

  Wrapper.displayName = `Controller(${Component.displayName || 'Anonymous'})`;
  Wrapper.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object,
    error: PropTypes.bool,
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  };
  Wrapper.defaultProps = {
    error: false,
    helperText: '',
  };
  return Wrapper;
}

export default makeController;
