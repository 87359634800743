import React, { useCallback } from 'react';

import Zoom from '@mui/material/Zoom';
import { SnackbarProvider, useSnackbar } from 'notistack';

const SUCCESS = 'success';
const ERROR = 'error';

function useMessage() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSuccessMessage = useCallback((message, options = {}) => {
    enqueueSnackbar(message, { ...options, variant: SUCCESS });
  }, [enqueueSnackbar]);

  const showErrorMessage = useCallback((message, options = {}) => {
    enqueueSnackbar(message, { ...options, variant: ERROR });
  }, [enqueueSnackbar]);

  return {
    success: showSuccessMessage,
    error: showErrorMessage,
    close: closeSnackbar,
  };
}

const CONFIG = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  TransitionComponent: Zoom,
};

function MessageProvider({ children }) {
  return <SnackbarProvider {...CONFIG}>{children}</SnackbarProvider>;
}

export { MessageProvider, useMessage };
