import React from 'react';

const DollarFormatter = ({ value }) => {
  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(value);

  return <span>{formattedValue}</span>;
};

export default DollarFormatter;
