import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import config from '@src/config';
import { useAuth } from '@src/context/common';

const PRINCIPALS = {
  ANY: '*',
  AUTHENTICATED: 'authenticated',
};

function Protect({ children, principal }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const renderAny = () => {
    return children;
  };

  const renderAuthenticated = () => {
    if (!isAuthenticated) {
      return (
        <Navigate to={{ pathname: config.LOGIN_URL, search: `?next=${location.pathname}` }} />
      );
    }
    return children;
  };


  const render = () => {
    switch (principal) {
    case PRINCIPALS.ANY:
      return renderAny();
    case PRINCIPALS.AUTHENTICATED:
      return renderAuthenticated();
    default:
      break;
    }
  };

  return render();
}

Protect.propTypes = {
  principal: PropTypes.oneOf(Object.values(PRINCIPALS)),
};
Protect.defaultProps = {
  principal: '*',
};
Protect.displayName = 'Protect';

function Authenticated({ children }) {
  return <Protect principal={PRINCIPALS.AUTHENTICATED} >{children}</Protect>;
}
Authenticated.displayName = 'Authenticated';

function Any({ children }) {
  return <Protect principal={PRINCIPALS.ANY}>{children}</Protect>;
}
Any.displayName = 'Any';

Protect.Authenticated = Authenticated;
Protect.Any = Any;
export default Protect;
